// To be separated into different files, add types and mocks
import { type HttpResponsePayload, createHttpEndpoint } from '../../utils'

export interface PostOrderEstimatedCommission {
  amount: number
  currency: string
}

export interface PostOrderPayload {
  id: number
  price: number
  currency: string
  estimatedCommission: PostOrderEstimatedCommission
}

export type PostOrderResponse = HttpResponsePayload<PostOrderPayload>

/**
 * Create BuyBack order
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/buyback-bm-api/definition#tag/Customer/operation/sourcingNewOrder}
 */
export const postOrder = createHttpEndpoint<PostOrderResponse>({
  method: 'POST',
  operationId: 'swapCreateOrder',
  path: '/bm/sourcing/order/new',
})

/**
 * BuyBack available shippings
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/buyback-bm-api/definition#tag/Customer/operation/buybackShipping}
 */
export const getShippingModes = createHttpEndpoint({
  method: 'GET',
  operationId: 'getBuybackShipping',
  path: '/bm/sourcing/shipping_modes/:listingId',
})

/**
 * Respond to a counter offer
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/buyback-bm-api/definition#tag/Customer/operation/putBuybackOrderCounterOffer}
 */
export const putCounterOffer = createHttpEndpoint({
  method: 'PUT',
  operationId: 'respondToCounterOffer',
  path: '/bm/buyback/v1/customer/orders/:id/counter-offer/pending',
})

/**
 * TODO Add API specs when available
 */
export const getOrderConfirmation = createHttpEndpoint({
  method: 'GET',
  operationId: 'getConfirmation',
  path: '/bm/sourcing/order/:id/confirmation',
})
